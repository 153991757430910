.full-gallery {

	&__in {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between; }

	&__item {
		margin-bottom: 40px;
		width: 290px; } }

.picture {
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
	padding: 10px;
	color: #4c4c4c;
	background-color: #fff;
	text-decoration: none;
	&:hover {
		img {
			transform: scale(1.1); } }

	&__media {
		width: 100%;
		height: 300px;
		margin-bottom: 20px;
		overflow: hidden;
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			transition: transform 0.4s ease; } }

	&__name {
		font-family: 'Architects Daughter', cursive;
		text-align: center;
		font-size: 22px;
		margin-bottom: 10px; }

	&__text {
		text-align: center;
		font-size: 14px; } }

.nav {
    display: flex;
    &__list {
        display: flex;
        align-items: center;

        li {
            display: flex;
            margin-right: 80px; }

        a {
            position: relative;
            font-family: 'Julius Sans One', sans-serif;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 2px;
            color: white;
            text-decoration: none;
            margin-top: -80px;
            opacity: 0;
            overflow: hidden;
            &:before {
                content: '';
                position: absolute;
                transition: transform .5s ease;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 2px;
                background-color: gold;
                transform: translateX(-110%); }

            &:hover {
                color: gold;
                &:before {
                    transform: translateX(0); } } }
        @include r(1024) {
            flex-direction: column;
            margin: 100px auto 0;
            a {
                font-size: 20px; }

            li {
                margin-bottom: 30px;
                margin-right: 0; } } } }

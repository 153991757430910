.contact {

    margin-bottom: 220px;
    font-family: 'Julius Sans One', sans-serif;

    &__in {
        display: flex;
        padding: 40px;
        background-color: #FFF;
        border-radius: 5px;
        box-shadow: 0px 2px 10px -5px #000000; }

    &__photo {
        display: flex;
        width: 420px;
        margin-right: 80px;

        img {
            width: 100%;
            border-radius: 5px;
            box-shadow: 0px 2px 10px -5px #000000; } }

    &__name {
        font-size: 24px;
        letter-spacing: 10px;
        margin-bottom: 20px; }

    &__subtitle {
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 2px;
        margin-bottom: 10px; }

    &__phone {
        display: flex;
        align-items: center;
        color: #4c4c4c;
        text-decoration: none;
        font-family: 'Julius Sans One', sans-serif;
        font-size: 20px;
        font-weight: 700;
        &:hover {
            svg {
                transform: scale(1.2); } } }

    &__location {
        display: flex;
        align-items: center;
        font-weight: 700;
        cursor: pointer;
        &:hover {
            svg {
                transform: scale(1.2); } } }

    &__inner {
        display: flex;
        align-items: flex-end;
        margin-bottom: 20px;
        label {
            width: 50%; } }

    &__wrap {
            margin-right: 40px; }


    svg {
        width: 34px;
        height: 34px;
        margin-right: 20px;
        transition: transform 0.4s ease; }

    &__form {
        display: flex;
        flex-direction: column;

        label {
            display: flex;
            flex-direction: column;
            &:first-child {
                margin-right: 20px; }
            span {
                font-size: 14px;
                font-weight: 700;
                margin-bottom: 5px; } }

        input {
            padding: 10px 20px;
            border: 2px solid rgb(0, 159, 232);
            border-radius: 5px;
            font-size: 18px;
            font-family: 'Julius Sans One', 'Jura', sans-serif;
            font-weight: 700; }
        textarea {
            resize: none;
            padding: 10px 20px;
            border: 2px solid rgb(0, 159, 232);
            border-radius: 5px;
            font-family: 'Julius Sans One', 'Jura', sans-serif;
            font-weight: 700;
            height: 180px;
            margin-bottom: 20px; }

        button.btn {
            position: relative;
            margin-left: auto;
            padding: 15px 30px;
            font-size: 20px;
            font-family: 'Julius Sans One', sans-serif;
            font-weight: 700;
            background-color: rgb(0, 159, 232);
            border-radius: 5px;
            color: white;
            overflow: hidden;
            &:after {
                background: #fff;
                content: '';
                height: 155px;
                left: -75px;
                opacity: .2;
                position: absolute;
                top: -50px;
                transform: rotate(35deg);
                transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
                width: 50px; }

            &:hover {
                color: gold;
                &:after {
                    left: 120%;
                    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1); } } } }



    @include r(1200) {
        margin-bottom: 60px;
        &__in {
            // background-color: transparent
            box-shadow: none; }

        &__photo {
            display: none; }

        &__col {
            width: 100%; }

        &__name {
            text-align: center; }

        &__inner {
            justify-content: space-between; }

        input, textarea {
            background-color: transparent; } }

    @include r(840) {

        &__inner {
            flex-direction: column;

            label {
                width: 100%;
                margin: 0 0 20px 0;
                &:first-child {
                    margin-right: 0; } } }

        &__wrap {
            width: 100%;
            margin: 0 0 20px 0; }

        &__location {
            font-size: 14px; }

        &__phone {
            font-size: 16px; } }

    @include r(480) {

        &__in {
            padding: 0;
            background-color: transparent; }

        input, textarea {
            border-color: #a0e1ff; } } }

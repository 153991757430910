html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; vertical-align: baseline; }

html { -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }

img, fieldset, a img { border: none; }

input[type="text"], input[type="email"], input[type="tel"], textarea { -webkit-appearance: none; }

input[type="submit"], button { cursor: pointer; }

input[type="submit"]::-moz-focus-inner, button::-moz-focus-inner { padding: 0; border: 0; }

textarea { overflow: auto; }

input, button { margin: 0; padding: 0; border: 0; }

div, input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus { outline: none; }

ul, ol { list-style-type: none; }

table { border-spacing: 0; border-collapse: collapse; width: 100%; }

html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

@media (prefers-reduced-motion: reduce) { * { transition: none !important; } }

.icon-arrow { width: 1.77em; height: 1em; fill: #C4C4C4; }

.icon-cloud { width: 1.54em; height: 1em; fill: none; }

.icon-facebook { width: 0.53em; height: 1em; fill: #000; }

.icon-fb { width: 1em; height: 1em; fill: #1777f2; }

.icon-insta { width: 1em; height: 1em; fill: initial; }

.icon-location { width: 1em; height: 1em; fill: none; }

.icon-phone { width: 1em; height: 1em; fill: #333; }

.icon-pint { width: 1em; height: 1em; fill: #ca2127; }

/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -moz-box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; }

.slick-track:before, .slick-track:after { content: ""; display: table; }

.slick-track:after { clear: both; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }

[dir="rtl"] .slick-slide { float: right; }

.slick-slide img { display: block; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

body { position: relative; min-height: 300vh; background-image: url("../img/footer-bg.png"), linear-gradient(to bottom, #e0c3fb, rgba(177, 233, 252, 0.7), rgba(3, 134, 206, 0.7)); background-size: 100vw; background-repeat: no-repeat; background-position: left bottom, top center; color: #4c4c4c; font-family: 'Roboto', sans-serif; line-height: 1.4; }

body.is-fixed { position: fixed; top: 0; left: 0; right: 0; }

body.gallery { min-height: 100vh; }

body.gallery .header .logo h1 { color: #4c4c4c; }

body.gallery .header .nav a { color: #4c4c4c; }

body.gallery .header .nav a:before { background-color: #4c4c4c; }

body.gallery .header .icon-insta, body.gallery .header .icon-fb, body.gallery .header .icon-pint { fill: #707070; }

body.gallery .header .icon-insta:hover { fill: url(#SVGID_1_); }

body.gallery .header .icon-pint:hover { fill: #ca2127; }

body.gallery .header .icon-fb:hover { fill: #0f92f3; }

@media only screen and (max-width: 768px) { body { font-size: 16px; } }

.out { position: relative; overflow: hidden; display: flex; flex-direction: column; min-height: 100vh; }

.container { width: 1240px; margin: 0 auto; }

@media only screen and (max-width: 1440px) { .container { width: 1080px; } }

@media only screen and (max-width: 1200px) { .container { width: 100%; padding: 0 30px; } }

.title { font-family: 'Julius Sans One', sans-serif; font-size: 46px; text-align: center; margin-bottom: 40px; padding-top: 80px; }

@media only screen and (max-width: 1440px) { .title { font-size: 36px; } }

@media only screen and (max-width: 1200px) { .title { padding-top: 60px; margin-bottom: 30px; } }

.logo h1 { position: relative; z-index: 10; font-family: 'Julius Sans One', sans-serif; font-size: 24px; letter-spacing: 10px; color: white; }

.logo h1 a { color: white; text-decoration: none; }

@media only screen and (max-width: 500px) { .logo h1 { font-size: 18px; letter-spacing: 5px; } }

.icon-insta, .icon-fb, .icon-pint { transition: all 0.3s ease; fill: #ccc; }

@media only screen and (max-width: 1024px) { .icon-insta { fill: url(#SVGID_1_); }
  .icon-fb { fill: #0f92f3; }
  .icon-pint { fill: #ca2127; } }

.icon-insta:hover, .icon-fb:hover, .icon-pint:hover { transform: scale(1.2); }

@media only screen and (max-width: 1024px) { .icon-insta:hover, .icon-fb:hover, .icon-pint:hover { transform: scale(1); } }

.icon-insta:hover { fill: url(#SVGID_1_); }

.icon-pint:hover { fill: #ca2127; }

.icon-fb:hover { fill: #0f92f3; }

.socials { display: flex; }

.socials li { display: flex; margin-right: 0; margin-top: -80px; }

.socials li:last-child { margin-right: 0 !important; }

.socials li a { display: flex; }

.socials svg { width: 24px; height: 24px; display: flex; }

.socials .icon { background-color: #fff; border-radius: 6px; }

@media only screen and (max-width: 1024px) { .socials { margin: auto auto 20px; }
  .socials svg { width: 34px; height: 34px; } }

.cloud { position: absolute; top: 1050px; left: 40px; z-index: -1; }

@media only screen and (max-width: 1200px) { .cloud { display: none; } }

.cloud svg { width: 100%; height: 100%; }

.cloud path { fill: #94ddfa; }

.cloud--2 { top: 1060px; right: 220px; left: auto; }

.cloud--2 path { fill: #8ddcfc; }

.cloud--3 { top: 1310px; left: 120px; }

.cloud--3 path { fill: #72bbd8; }

.cloud--4 { top: 1330px; right: 120px; left: auto; }

.cloud--4 path { fill: #769ddb; }

.cloud--5 { top: 1030px; left: 220px; }

.cloud--5 path { fill: #7297e7; }

.cloud--6 { top: 1380px; left: 70px; }

.cloud--6 path { fill: #5c85bb; }

.cloud--7 { top: 1030px; right: 520px; left: auto; }

.cloud--7 path { fill: #5da9c7; }

.cloud--8 { top: 1530px; right: 120px; left: auto; }

.cloud--8 path { fill: #6b6abe; }

.cloud--9 { top: 1680px; left: 120px; }

.cloud--9 path { fill: #97dffb; }

.cloud--10 { top: 1050px; left: 0; }

.cloud--11 { top: 1350px; left: 0; }

.cloud--12 { top: 1380px; left: 40px; }

.cloud--13 { top: 1260px; left: auto; right: 0; }

.cloud--14 { top: 1150px; left: auto; right: 0; }

.preloader { width: 100%; height: 100%; background: transparent url("../img/elia2.jpg") top center; background-size: 200px; position: absolute; z-index: 9999; transform: translateX(100%); opacity: 0.1; }

.preloader-bg { top: 0; left: 0; bottom: 0; right: 0; background-image: linear-gradient(to bottom, #e0c3fb, #b1e9fc, #0386ce); position: absolute; z-index: 9998; }

.footer { margin-top: auto; }

.footer__in { display: flex; justify-content: space-aroud; padding: 20px 0; }

.footer .nav { margin-left: auto; }

.footer .nav a { margin-top: 0; opacity: 1; }

.footer .socials li { margin-top: 0; margin-right: 20px; }

.footer .socials li:last-child { margin-right: 0; }

@media only screen and (max-width: 1024px) { .footer__in { justify-content: space-around; }
  .footer .socials { margin: 0; }
  .footer .nav { margin-left: 0; }
  .footer .nav__list { margin: 0; flex-direction: row; }
  .footer .nav__list li { margin-right: 20px; margin-bottom: 0; } }

.fancybox-caption__body b { font-size: 24px; font-family: 'Julius Sans One', sans-serif; }

.header { position: absolute; top: 0; left: 0; width: 100%; z-index: 5; }

.header__in { display: flex; align-items: center; justify-content: space-between; align-items: center; padding: 20px 0; }

.header__menu { display: flex; }

@media only screen and (max-width: 1024px) { .header__menu { position: fixed; top: 0; right: 0; bottom: 0; left: 0; background-color: #396180; flex-direction: column; transform: translateX(100%); transition: transform 0.4s ease; }
  .header__menu.is-active { transform: translateX(0); } }

.header__burger { display: none; height: auto; width: 32px; background-color: transparent; z-index: 10; }

@media only screen and (max-width: 1024px) { .header__burger { display: block; }
  .header__burger--none { display: none; } }

.header__burger span { transition: 0.3s; display: block; height: 3px; width: 100%; background-color: white; border-radius: 10px; }

.header__burger span:not(:last-child) { margin-bottom: 5px; }

.header__burger.is-active span:nth-child(1) { transform: translateY(8px) rotate(45deg); }

.header__burger.is-active span:nth-child(2) { transform: translateX(100%); opacity: 0; visibility: hidden; }

.header__burger.is-active span:nth-child(3) { transform: translateY(-8px) rotate(-45deg); }

.slider { position: relative; }

.slider__btn { position: absolute; top: 50%; left: 50px; transform: translateY(-50%); display: flex; -webkit-justify-content: center; justify-content: center; -webkit-align-items: center; align-items: center; padding: 20px; overflow: hidden; border-radius: 50%; width: 100px; height: 100px; background-color: rgba(0, 0, 0, 0.3); transition: all 0.4s ease; }

.slider__btn--next { left: auto; right: 50px; }

.slider__btn--prev { transform: translateY(-50%) rotate(180deg); }

.slider__btn svg { font-size: 45px; fill: #87bee7; transition: all 0.4s ease; }

.slider__btn:hover { background-color: rgba(0, 0, 0, 0.5); }

@media only screen and (max-width: 1200px) { .slider { padding-bottom: 60px; }
  .slider__btn { left: 30px; width: 60px; height: 60px; padding: 10px; }
  .slider__btn--next { left: auto; right: 30px; } }

@media only screen and (max-width: 768px) { .slider__btn { display: none !important; } }

.slide { position: relative; opacity: 0.5; height: 600px; display: flex; margin: 40px; transition: all 0.4s ease; pointer-events: none; box-shadow: 0 2px 10px -5px #000000; overflow: hidden; }

.slide img { width: 100%; object-fit: cover; object-position: top center; border-radius: 5px; }

.slide:hover .slide__descr { transform: translateY(0); }

.slide__descr { position: absolute; top: 0; bottom: 0; left: 0; right: 0; background-color: rgba(0, 0, 0, 0.4); color: white; display: flex; padding-bottom: 40px; flex-direction: column; align-items: center; justify-content: flex-end; transform: translateY(-100%); transition: transform 0.3s ease; }

@media only screen and (max-width: 1440px) { .slide { height: 500px; } }

@media only screen and (max-width: 1200px) { .slide { height: 400px; } }

@media only screen and (max-width: 1024px) { .slide img { margin: 0; } }

.slick-center .slide { opacity: 1; transform: scale(1.1); pointer-events: auto; }

.hero { overflow: hidden; height: 100vh; box-shadow: 0px 4px 15px; }

.hero__in { position: relative; height: 100%; width: 100%; }

.hero__imgs { position: relative; display: flex; height: 100%; }

.hero__imgs-bg { position: absolute; top: 0; bottom: 0; left: 0; right: 0; background: transparent url("../img/hero1.jpg") top -150px center no-repeat; background-size: cover; opacity: 0.5; }

.hero__imgs-bg.is-show { display: block; }

.hero__img { display: flex; height: 100%; width: 100%; transform: translateX(-50%); flex-shrink: 0; background-color: #DACDFB; overflow: hidden; }

.hero__img:before { content: ''; position: absolute; display: block; right: -30px; transform: skewY(-45deg); transform-origin: left; width: 30px; height: 100%; background: transparent url("../img/hero1.jpg") top no-repeat; background-size: 100vw; opacity: 0.5; }

.hero__img img { width: 100%; object-fit: cover; object-position: calc(-50vw + 8px) -150px; opacity: 0.5; }

.hero__img--left img { object-position: calc(50vw - 8px) -150px; }

.hero__img--right:before { top: -30px; right: auto; left: -30px; transform: skewY(-135deg); }

.hero__title { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); display: flex; flex-direction: column; white-space: nowrap; font-size: 56px; font-family: 'Architects Daughter', cursive; font-weight: 400; color: white; z-index: 2; }

.hero__title span:last-child { position: absolute; bottom: -50px; right: 0; font-size: 22px; opacity: 1; }

@media only screen and (max-width: 1440px) { .hero__title { font-size: 42px; text-align: center; } }

.hero__overlay { position: absolute; top: 0; bottom: 0; left: 0; right: 0; z-index: 1; background-color: rgba(0, 41, 64, 0.7); }

.hero__overlay--large.is-show { display: block; }

.hero__arrow { display: flex; position: absolute; bottom: 25px; left: 50%; font-size: 24px; z-index: 1; background-color: transparent; transform: translate(-50px, -7px) rotate(88deg); animation-duration: 3s; animation-iteration-count: infinite; }

.hero__arrow svg { fill: white; transition: transform 0.4s ease; }

.hero__arrow.is-animation { animation-name: arrow; }

.hero__arrow:hover svg { transform: scale(1.2); }

@media only screen and (max-width: 1380px) { .hero__imgs-bg { background-size: 1700px; } }

@media only screen and (max-width: 960px) { .hero__title { width: 100%; padding: 0 30px; white-space: initial; font-size: 32px; }
  .hero__title span:last-child { right: 50px; font-size: 18px; } }

@media only screen and (max-width: 600px) { .hero__arrow { left: 54%; } }

@keyframes arrow { 0% { transform: translate(-50px, -7px) rotate(88deg); }
  50% { transform: translate(-50px, 7px) rotate(88deg); }
  100% { transform: translate(-50px, -7px) rotate(88deg); } }

.about__wrapper { display: flex; margin-bottom: 40px; }

.about__wrapper--white { padding: 40px; background-color: #fff; border-radius: 5px; box-shadow: 0px 2px 10px -5px #000000; }

.about__text p { margin-bottom: 20px; font-size: 18px; }

.about__img { display: flex; margin-right: 40px; box-shadow: 0px 2px 10px -5px #000000; }

.about__img img { border-radius: 5px; object-fit: cover; }

.about__subtitle { text-align: center; font-weight: 400; font-family: 'Julius Sans One', sans-serif; font-size: 32px; margin-bottom: 20px; }

.about__col { padding: 40px; margin-right: 40px; background-color: #fff; border-radius: 5px; box-shadow: 0px 2px 10px -5px #000000; }

.about__col:last-child { margin-right: 0; }

.about__list li { margin-bottom: 10px; }

@media only screen and (max-width: 1200px) { .about { background-color: #fff; padding-bottom: 30px; }
  .about__wrapper { margin-bottom: 30px; flex-direction: column-reverse; }
  .about__wrapper:last-child { margin-bottom: 0; }
  .about__wrapper--white, .about__col { background-color: transparent; box-shadow: none; border-radius: none; padding: 0; }
  .about__subtitle { font-size: 26px; }
  .about__img { margin-right: 0; box-shadow: none; justify-content: center; }
  .about__list { margin-bottom: 30px; }
  .about__col { margin-right: 0; } }

@media only screen and (max-width: 768px) { .about__text p { font-size: 16px; } }

.insta__in { display: flex; }

.insta__imgs { display: flex; margin-left: 100px; }

.insta__img { display: flex; width: 300px; height: 500px; margin-right: 20px; background-color: #FFF; border-radius: 5px; box-shadow: 0 2px 10px -5px #000000; overflow: hidden; z-index: 1; }

.insta__img:last-child { margin-right: 0; }

.insta__img img { width: 100%; object-fit: cover; animation-name: example; animation-duration: 30s; animation-iteration-count: infinite; }

.insta__text { display: flex; flex-direction: column; justify-content: space-around; min-height: 500px; min-width: 700px; max-width: 700px; margin-left: -100px; margin-right: 200px; margin-top: 100px; padding: 40px; padding-left: 140px; font-family: 'Architects Daughter', cursive; font-size: 20px; background-color: #FFF; border-radius: 5px; box-shadow: 0 2px 10px -5px #000000; }

.insta__text p { margin-bottom: 20px; }

@media only screen and (max-width: 1800px) { .insta__img { width: 250px; height: 350px; } }

@media only screen and (max-width: 1600px) { .insta__text { font-size: 18px; min-height: 300px; min-width: 500px; padding-left: 90px; margin-left: -50px; } }

@media only screen and (max-width: 1500px) { .insta__imgs { margin-left: 50px; } }

@media only screen and (max-width: 1360px) { .insta__in { flex-direction: column; align-items: center; }
  .insta__imgs { margin: 0; justify-content: center; margin-bottom: 30px; }
  .insta__text { margin: 0; } }

@media only screen and (max-width: 1200px) { .insta { background-color: #fff; }
  .insta__text { box-shadow: none; } }

@media only screen and (max-width: 940px) { .insta__imgs { justify-content: space-between; width: 100%; padding: 0 30px; }
  .insta__img { width: 30%; margin: 0 !important; }
  .insta__text { box-shadow: none; padding: 0 30px; width: auto; text-align: center; } }

@media only screen and (max-width: 650px) { .insta__img { height: 150px; }
  .insta__text { padding: 0 30px; min-width: auto; } }

@keyframes example { 0% { transform: scale(1.2); }
  50% { transform: scale(1); }
  100% { transform: scale(1.2); } }

.contact { margin-bottom: 220px; font-family: 'Julius Sans One', sans-serif; }

.contact__in { display: flex; padding: 40px; background-color: #FFF; border-radius: 5px; box-shadow: 0px 2px 10px -5px #000000; }

.contact__photo { display: flex; width: 420px; margin-right: 80px; }

.contact__photo img { width: 100%; border-radius: 5px; box-shadow: 0px 2px 10px -5px #000000; }

.contact__name { font-size: 24px; letter-spacing: 10px; margin-bottom: 20px; }

.contact__subtitle { font-weight: 700; font-size: 14px; letter-spacing: 2px; margin-bottom: 10px; }

.contact__phone { display: flex; align-items: center; color: #4c4c4c; text-decoration: none; font-family: 'Julius Sans One', sans-serif; font-size: 20px; font-weight: 700; }

.contact__phone:hover svg { transform: scale(1.2); }

.contact__location { display: flex; align-items: center; font-weight: 700; cursor: pointer; }

.contact__location:hover svg { transform: scale(1.2); }

.contact__inner { display: flex; align-items: flex-end; margin-bottom: 20px; }

.contact__inner label { width: 50%; }

.contact__wrap { margin-right: 40px; }

.contact svg { width: 34px; height: 34px; margin-right: 20px; transition: transform 0.4s ease; }

.contact__form { display: flex; flex-direction: column; }

.contact__form label { display: flex; flex-direction: column; }

.contact__form label:first-child { margin-right: 20px; }

.contact__form label span { font-size: 14px; font-weight: 700; margin-bottom: 5px; }

.contact__form input { padding: 10px 20px; border: 2px solid #009fe8; border-radius: 5px; font-size: 18px; font-family: 'Julius Sans One', 'Jura', sans-serif; font-weight: 700; }

.contact__form textarea { resize: none; padding: 10px 20px; border: 2px solid #009fe8; border-radius: 5px; font-family: 'Julius Sans One', 'Jura', sans-serif; font-weight: 700; height: 180px; margin-bottom: 20px; }

.contact__form button.btn { position: relative; margin-left: auto; padding: 15px 30px; font-size: 20px; font-family: 'Julius Sans One', sans-serif; font-weight: 700; background-color: #009fe8; border-radius: 5px; color: white; overflow: hidden; }

.contact__form button.btn:after { background: #fff; content: ''; height: 155px; left: -75px; opacity: .2; position: absolute; top: -50px; transform: rotate(35deg); transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1); width: 50px; }

.contact__form button.btn:hover { color: gold; }

.contact__form button.btn:hover:after { left: 120%; transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1); }

@media only screen and (max-width: 1200px) { .contact { margin-bottom: 60px; }
  .contact__in { box-shadow: none; }
  .contact__photo { display: none; }
  .contact__col { width: 100%; }
  .contact__name { text-align: center; }
  .contact__inner { justify-content: space-between; }
  .contact input, .contact textarea { background-color: transparent; } }

@media only screen and (max-width: 840px) { .contact__inner { flex-direction: column; }
  .contact__inner label { width: 100%; margin: 0 0 20px 0; }
  .contact__inner label:first-child { margin-right: 0; }
  .contact__wrap { width: 100%; margin: 0 0 20px 0; }
  .contact__location { font-size: 14px; }
  .contact__phone { font-size: 16px; } }

@media only screen and (max-width: 480px) { .contact__in { padding: 0; background-color: transparent; }
  .contact input, .contact textarea { border-color: #a0e1ff; } }

.nav { display: flex; }

.nav__list { display: flex; align-items: center; }

.nav__list li { display: flex; margin-right: 80px; }

.nav__list a { position: relative; font-family: 'Julius Sans One', sans-serif; font-size: 14px; font-weight: 700; letter-spacing: 2px; color: white; text-decoration: none; margin-top: -80px; opacity: 0; overflow: hidden; }

.nav__list a:before { content: ''; position: absolute; transition: transform .5s ease; left: 0; bottom: 0; width: 100%; height: 2px; background-color: gold; transform: translateX(-110%); }

.nav__list a:hover { color: gold; }

.nav__list a:hover:before { transform: translateX(0); }

@media only screen and (max-width: 1024px) { .nav__list { flex-direction: column; margin: 100px auto 0; }
  .nav__list a { font-size: 20px; }
  .nav__list li { margin-bottom: 30px; margin-right: 0; } }

.full-gallery__in { display: flex; flex-wrap: wrap; justify-content: space-between; }

.full-gallery__item { margin-bottom: 40px; width: 290px; }

.gallery__link { position: relative; display: block; width: 240px; margin: 60px auto 0; padding: 15px; text-align: center; font-size: 20px; font-family: 'Julius Sans One', sans-serif; font-weight: 700; background-color: #009fe8; border-radius: 5px; color: white; text-decoration: none; overflow: hidden; }

.gallery__link:after { background: #fff; content: ''; height: 155px; left: -75px; opacity: .2; position: absolute; top: -50px; transform: rotate(35deg); transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1); width: 50px; }

.gallery__link:hover { color: gold; }

.gallery__link:hover:after { left: 120%; transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1); }

.picture { display: flex; flex-direction: column; border-radius: 5px; border: 1px solid rgba(0, 0, 0, 0.2); box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15); padding: 10px; color: #4c4c4c; background-color: #fff; text-decoration: none; }

.picture:hover img { transform: scale(1.1); }

.picture__media { width: 100%; height: 300px; margin-bottom: 20px; overflow: hidden; }

.picture__media img { height: 100%; width: 100%; object-fit: cover; transition: transform 0.4s ease; }

.picture__name { font-family: 'Architects Daughter', cursive; text-align: center; font-size: 22px; margin-bottom: 10px; }

.picture__text { text-align: center; font-size: 14px; }

.news { margin-bottom: 80px; }

.posts__item { margin-bottom: 40px; }

.post { padding: 40px; background-color: #fff; border-radius: 5px; }

.post__content { display: flex; justify-content: space-between; }

.post__wrap { width: 65%; }

.post__media { display: flex; width: 30%; height: 30%; min-height: 260px; border-radius: 5px; overflow: hidden; }

.post__media img { width: 100%; object-fit: cover; }

.post__title { font-size: 28px; margin-bottom: 20px; }

.post__text { margin-bottom: 20px; }

.post__date { font-size: 14px; color: gray; }

.slider {
	position: relative;

	&__btn {
		position: absolute;
		top: 50%;
		left: 50px;
		transform: translateY(-50%);

		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-align-items: center;
		align-items: center;
		padding: 20px;
		overflow: hidden;
		border-radius: 50%;
		width: 100px;
		height: 100px;
		background-color: rgba(0, 0, 0, 0.3);

		transition: all 0.4s ease;

		&--next {
			left: auto;
			right: 50px; }

		&--prev {
			transform: translateY(-50%) rotate(180deg); }

		svg {
			font-size: 45px;
			fill: #87bee7;
			transition: all 0.4s ease; }


		&:hover {
			background-color: rgba(0, 0, 0, 0.5);

			svg {
 } } }				// fill: gold

	@include r(1200) {
		padding-bottom: 60px;

		&__btn {
			left: 30px;
			width: 60px;
			height: 60px;
			padding: 10px;
			&--next {
				left: auto;
				right: 30px; } } }

	@include r(768) {
		&__btn {
			display: none!important; } } }
.slide {
	position: relative;
	opacity: 0.5;
	height: 600px;
	display: flex;
	margin: 40px;
	transition: all 0.4s ease;
	pointer-events: none;
	box-shadow: 0 2px 10px -5px #000000;
	overflow: hidden;

	img {
		width: 100%;
		object-fit: cover;
		object-position: top center;
		// margin: 10px
		border-radius: 5px; }

	&:hover {
		.slide__descr {
			transform: translateY(0); } }

	&__descr {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0.4);
		color: white;
		display: flex;
		padding-bottom: 40px;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		transform: translateY(-100%);
		transition: transform 0.3s ease; }


	@include r(1440) {
		height: 500px; }
	@include r(1200) {
		height: 400px; }

	@include r(1024) {
		img {
			margin: 0; } } }

.slick-center .slide {
	opacity: 1;
	transform: scale(1.1);
	pointer-events: auto; }







.insta {

    &__in {
        display: flex; }

    &__imgs {
        display: flex;
        margin-left: 100px; }

    &__img {
        display: flex;
        width: 300px;
        height: 500px;
        margin-right: 20px;
        // padding: 10px
        background-color: #FFF;
        border-radius: 5px;
        box-shadow: 0 2px 10px -5px #000000;
        overflow: hidden;
        z-index: 1;
        &:last-child {
            margin-right: 0; }

        img {
            width: 100%;
            object-fit: cover;
            animation-name: example;
            animation-duration: 30s;
            animation-iteration-count: infinite; }

        &:hover {
            img {} } }



    &__text {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        min-height: 500px;
        min-width: 700px;
        max-width: 700px;

        margin-left: -100px;
        margin-right: 200px;
        margin-top: 100px;

        padding: 40px;
        padding-left: 140px;

        font-family: 'Architects Daughter', cursive;
        font-size: 20px;

        background-color: #FFF;
        border-radius: 5px;
        box-shadow: 0 2px 10px -5px #000000;
        p {
            margin-bottom: 20px; } }


    @include r(1800) {

        &__img {
            width: 250px;
            height: 350px; } }

    @include r(1600) {

        &__text {
            font-size: 18px;
            min-height: 300px;
            min-width: 500px;
            padding-left: 90px;
            margin-left: -50px; } }

    @include r(1500) {

        &__imgs {
            margin-left: 50px; } }

    @include r(1360) {

        &__in {
            flex-direction: column;
            align-items: center; }

        &__imgs {
            margin: 0;
            justify-content: center;
            margin-bottom: 30px; }

        &__text {
            margin: 0; } }

    @include r(1200) {
        background-color: #fff;

        &__text {
            box-shadow: none; } }

    @include r(940) {
        &__imgs {
            justify-content: space-between;
            width: 100%;
            padding: 0 30px; }

        &__img {
            width: 30%;
            margin: 0!important; }

        &__text {
            box-shadow: none;
            padding: 0 30px;
            width: auto;
            text-align: center; } }

    @include r(650) {
        &__img {
            height: 150px; }

        &__text {
            padding: 0 30px;
            min-width: auto; } } }

@keyframes example {
    0% {
        transform: scale(1.2); }
    50% {
        transform: scale(1); }
    100% {
        transform: scale(1.2); } }

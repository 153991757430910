.gallery {

	&__link {
		position: relative;
		display: block;
		width: 240px;
		margin: 60px auto 0;
		padding: 15px;
		text-align: center;
		font-size: 20px;
		font-family: 'Julius Sans One', sans-serif;
		font-weight: 700;
		background-color: rgb(0, 159, 232);
		border-radius: 5px;
		color: white;
		text-decoration: none;
		overflow: hidden;
		&:after {
			background: #fff;
			content: '';
			height: 155px;
			left: -75px;
			opacity: .2;
			position: absolute;
			top: -50px;
			transform: rotate(35deg);
			transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
			width: 50px; }

		&:hover {
			color: gold;
			&:after {
				left: 120%;
				transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1); } } } }

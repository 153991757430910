.icon-arrow {
  width: 1.77em;
  height: 1em;
  fill: #C4C4C4;
}
.icon-cloud {
  width: 1.54em;
  height: 1em;
  fill: none;
}
.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: #000;
}
.icon-fb {
  width: 1em;
  height: 1em;
  fill: #1777f2;
}
.icon-insta {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-location {
  width: 1em;
  height: 1em;
  fill: none;
}
.icon-phone {
  width: 1em;
  height: 1em;
  fill: #333;
}
.icon-pint {
  width: 1em;
  height: 1em;
  fill: #ca2127;
}

.header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 5;

	&__in {
		display: flex;
		align-items: center;
		justify-content: space-between;
		align-items: center;
		padding: 20px 0; }

	&__menu {
		display: flex;
		@include r(1024) {
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: #396180;
			flex-direction: column;
			transform: translateX(100%);
			transition: transform 0.4s ease;
			&.is-active {

				transform: translateX(0); } } }

	&__burger {
		display: none;
		height: auto;
		width: 32px;

		background-color: transparent;
		z-index: 10;
		@include r(1024) {
			display: block;
			&--none {
				display: none; } }
		span {
			transition: 0.3s;
			display: block;
			height: 3px;
			width: 100%;

			background-color: white;
			border-radius: 10px;

			&:not(:last-child) {
				margin-bottom: 5px; } }

		&.is-active {
			span {
				&:nth-child(1) {
					transform: translateY(8px) rotate(45deg); }

				&:nth-child(2) {
					transform: translateX(100%);
					opacity: 0;
					visibility: hidden; }

				&:nth-child(3) {
					transform: translateY(-8px) rotate(-45deg); } } } } }


.hero {
	overflow: hidden;
	height: 100vh;
	box-shadow: 0px 4px 15px;

	&__in {
		position: relative;
		height: 100%;
		width: 100%; }

	&__imgs {
		position: relative;
		display: flex;
		height: 100%; }


	&__imgs-bg {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: transparent url('../img/hero1.jpg') top -150px center no-repeat;
		background-size: cover;
		opacity: 0.5;
		// display: none
		&.is-show {
			display: block; } }



	&__img {
		display: flex;
		height: 100%;
		width: 100%;
		transform: translateX(-50%);
		flex-shrink: 0;
		background-color: #DACDFB;
		overflow: hidden;

		&:before {
			content: '';
			position: absolute;
			display: block;
			right: -30px;
			transform: skewY(-45deg);
			transform-origin: left;
			width: 30px;
			height: 100%;
			background: transparent url('../img/hero1.jpg') top no-repeat;
			background-size: 100vw;
			opacity: 0.5; }
		img {
			width: 100%;
			object-fit: cover;
			object-position: calc(-50vw + 8px) -150px;
			opacity: 0.5; }

		&--left {
			img {
				object-position: calc(50vw - 8px) -150px; } }

		&--right {
			&:before {
				top: -30px;
				right: auto;
				left: -30px;
				transform: skewY(-135deg); } } }


	&__title {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		flex-direction: column;
		white-space: nowrap;
		font-size: 56px;
		font-family: 'Architects Daughter', cursive;
		font-weight: 400;
		color: white;
		z-index: 2;

		span:last-child {
			position: absolute;
			bottom: -50px;
			right: 0;
			font-size: 22px;
			opacity: 1; }

		@include r(1440) {
			font-size: 42px;
			text-align: center; } }



	&__overlay {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;
		background-color: rgba(0, 41, 64, 0.7);

		&--large {
			// display: none
			&.is-show {
				display: block; } } }


	&__arrow {
		display: flex;
		position: absolute;
		bottom: 25px;
		left: 50%;
		font-size: 24px;
		z-index: 1;
		background-color: transparent;
		transform: translate(-50px, -7px) rotate(88deg);
		animation-duration: 3s;
		animation-iteration-count: infinite;

		svg {
			fill: white;
			transition: transform 0.4s ease; }

		&.is-animation {
			animation-name: arrow; }
		&:hover {
			svg {
				// fill: gold
				transform: scale(1.2); } } }

	@include r(1380) {
		&__imgs-bg {
			background-size: 1700px; } }

	@include r(960) {
		&__title {
			width: 100%;
			padding: 0 30px;
			white-space: initial;
			font-size: 32px;
			span:last-child {
				right: 50px;
				font-size: 18px; } } }

	@include r(600) {
		&__arrow {
			left: 54%; } } }

@keyframes arrow {
	0% {
		transform: translate(-50px, -7px) rotate(88deg); }
	50% {
		transform: translate(-50px, 7px) rotate(88deg); }
	100% {
		transform: translate(-50px, -7px) rotate(88deg); } }



.about {


	&__wrapper {
		display: flex;
		margin-bottom: 40px;
		&--white {
			padding: 40px;
			background-color: #fff;
			border-radius: 5px;
			box-shadow: 0px 2px 10px -5px #000000; } }


	&__text p {
		margin-bottom: 20px;
		font-size: 18px;
		// &:last-child
 }		// 	margin-bottom: 0

	&__img {
		display: flex;
		margin-right: 40px;
		box-shadow: 0px 2px 10px -5px #000000;
		img {
			border-radius: 5px;
			object-fit: cover; } }

	&__subtitle {
		text-align: center;
		font-weight: 400;
		font-family: 'Julius Sans One', sans-serif;
		font-size: 32px;
		margin-bottom: 20px; }

	&__col {
		padding: 40px;
		margin-right: 40px;
		background-color: #fff;
		border-radius: 5px;
		box-shadow: 0px 2px 10px -5px #000000;
		&:last-child {
			margin-right: 0; } }

	&__list {

		li {
			margin-bottom: 10px; } }

	@include r(1200) {
		background-color: #fff;
		padding-bottom: 30px;
		&__wrapper {
			margin-bottom: 30px;
			flex-direction: column-reverse;
			&:last-child {
				margin-bottom: 0; } }
		&__wrapper--white, &__col {
			background-color: transparent;
			box-shadow: none;
			border-radius: none;
			padding: 0; }
		&__subtitle {
			font-size: 26px; }

		&__img {
			margin-right: 0;
			box-shadow: none;
			justify-content: center; }

		&__list {
			margin-bottom: 30px; }

		&__col {
			margin-right: 0; } }


	@include r(768) {
		&__text p {
			font-size: 16px; } } }

body {
    position: relative;
    min-height: 300vh;
    background-image: url('../img/footer-bg.png'), linear-gradient(to bottom,  #e0c3fb, rgba(#b1e9fc, 0.7), rgba(#0386ce, 0.7));
    background-size: 100vw;
    background-repeat: no-repeat;
    background-position: left bottom, top center;
    color: #4c4c4c;
    font-family: 'Roboto', sans-serif;
    line-height: 1.4;

    &.is-fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0; }

    &.gallery {
        min-height: 100vh;

        .header {
            .logo h1 {
                color: #4c4c4c; }

            .nav a {
                color: #4c4c4c;
                &:before {
                    background-color: #4c4c4c; } }

            .icon-insta, .icon-fb, .icon-pint {
                fill: #707070; }
            .icon-insta:hover {
                fill: url(#SVGID_1_); }

            .icon-pint:hover {
                fill: #ca2127; }

            .icon-fb:hover {
                fill: #0f92f3; } } }

    @include r(768) {

        font-size: 16px; } }


.out {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh; }
.container {
    width: 1240px;
    margin: 0 auto;

    @include r(1440) {
        width: 1080px; }

    @include r(1200) {
        width: 100%;
        padding: 0 30px; } }

.title {
    font-family: 'Julius Sans One', sans-serif;
    font-size: 46px;
    text-align: center;
    margin-bottom: 40px;
    padding-top: 80px;

    @include r(1440) {
        font-size: 36px; }

    @include r(1200) {
        padding-top: 60px;
        margin-bottom: 30px; } }

.logo h1 {
    position: relative;
    z-index: 10;
    font-family: 'Julius Sans One', sans-serif;
    font-size: 24px;
    letter-spacing: 10px;
    color: white;
    a {
        color: white;
        text-decoration: none; }

    @include r(500) {
        font-size: 18px;
        letter-spacing: 5px; } }

.icon-insta, .icon-fb, .icon-pint {
    transition: all 0.3s ease;
    fill: #ccc; }

@include r(1024) {
    .icon-insta {
        fill: url(#SVGID_1_); }
    .icon-fb {
        fill: #0f92f3; }
    .icon-pint {
        fill: #ca2127; } }

.icon-insta:hover, .icon-fb:hover, .icon-pint:hover {
    transform: scale(1.2);
    @include r(1024) {
        transform: scale(1); } }

.icon-insta:hover {
    fill: url(#SVGID_1_); }

.icon-pint:hover {
    fill: #ca2127; }

.icon-fb:hover {
    fill: #0f92f3; }




.socials {
    display: flex;

    li {
        display: flex;
        margin-right: 0;
        margin-top: -80px;
        &:last-child {
            margin-right: 0!important; }
        a {
            display: flex; } }
    svg {
        width: 24px;
        height: 24px;
        display: flex; }

    .icon {
        background-color: #fff;
        border-radius: 6px; }

    @include r(1024) {
        margin: auto auto 20px;

        svg {
            width: 34px;
            height: 34px; } } }

.cloud {
    position: absolute;

    top: 1050px;
    left: 40px;

    z-index: -1;
    @include r(1200) {
        display: none; }

    svg {
        width: 100%;
        height: 100%; }
    path {
        fill: #94ddfa; }

    &--2 {
        top: 1060px;
        right: 220px;
        left: auto;
        path {
            fill: #8ddcfc; } }

    &--3 {
        top: 1310px;
        left: 120px;
        path {
            fill: #72bbd8; } }

    &--4 {
        top: 1330px;
        right: 120px;
        left: auto;
        path {
            fill: #769ddb; } }
    &--5 {
        top: 1030px;
        left: 220px;
        path {
            fill: #7297e7; } }

    &--6 {
        top: 1380px;
        left: 70px;
        path {
            fill: #5c85bb; } }

    &--7 {
        top: 1030px;
        right: 520px;
        left: auto;
        path {
            fill: #5da9c7; } }

    &--8 {
        top: 1530px;
        right: 120px;
        left: auto;
        path {
            fill: #6b6abe; } }

    &--9 {
        top: 1680px;
        left: 120px;
        path {
            fill: #97dffb; } }

    &--10 {
        top: 1050px;
        left: 0; }

    &--11 {
        top: 1350px;
        left: 0; }

    &--12 {
        top: 1380px;
        left: 40px; }

    &--13 {
        top: 1260px;
        left: auto;
        right: 0; }

    &--14 {
        top: 1150px;
        left: auto;
        right: 0; } }


.preloader {
    width: 100%;
    height: 100%;
    background: transparent url('../img/elia2.jpg') top center;
    background-size: 200px;
    position: absolute;
    z-index: 9999;
    transform: translateX(100%);
    opacity: 0.1; }


.preloader-bg {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(to bottom,  #e0c3fb, rgba(#b1e9fc, 1), rgba(#0386ce, 1));
    position: absolute;
    z-index: 9998; }

.footer {
    margin-top: auto;

    &__in {
        display: flex;
        justify-content: space-aroud;
        padding: 20px 0; }

    .nav {
        margin-left: auto;

        a {
            margin-top: 0;
            opacity: 1; } }

    .socials {

        li {
            margin-top: 0;
            margin-right: 20px;
            &:last-child {
                margin-right: 0; } } }

    @include r(1024) {

        &__in {
            justify-content: space-around; }
        .socials {
            margin: 0; }

        .nav {
            margin-left: 0;
            &__list {
                margin: 0;
                flex-direction: row;

                li {
                    margin-right: 20px;
                    margin-bottom: 0; } } } } }

.fancybox-caption__body {
    b {
        font-size: 24px;
        font-family: 'Julius Sans One', sans-serif; } }

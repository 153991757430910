.posts {

	&__item {
		margin-bottom: 40px; } }

.post {
	padding: 40px;
	background-color: #fff;
	border-radius: 5px;

	&__content {
		display: flex;
		justify-content: space-between; }

	&__wrap {
		width: 65%; }

	&__media {
		display: flex;
		width: 30%;
		height: 30%;
		min-height: 260px;
		border-radius: 5px;
		overflow: hidden;
		img {
			width: 100%;
			object-fit: cover; } }

	&__title {
		font-size: 28px;
		margin-bottom: 20px; }

	&__text {
		margin-bottom: 20px; }

	&__date {
		font-size: 14px;
		color: gray; } }
